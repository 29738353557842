import React, { useState, useEffect } from 'react'
import axios from 'axios';
import toast from 'react-hot-toast';
import './active-videos.css';

const ActiveVideos = () => {
  const [monitoredVideos, setMonitoredVideos] = useState([]);

  const getMonitoredVideos = () => {
    axios.get("https://younity-backend.penpencil.co/v1/chat/monitored-videos")
      .then(response => {
        // console.log(response.data);
        setMonitoredVideos(response.data);
      }).catch(error => {
        console.error('Error:', error);
      });
  }

  useEffect(() => {
    getMonitoredVideos();
    const interval = setInterval(() => {
      getMonitoredVideos();
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      <h3 style={{marginTop: 0}}>{`Monitored Videos (${monitoredVideos?.data?.length})`}</h3>
      <div style={{padding: 5 }}>
        <table>
          <thead>
            <tr>
              <th>Channel Name</th>
              <th>Video Title</th>
              <th>Video Id</th>
              {/* <th>Stop</th> */}
            </tr>
          </thead>
          <tbody className='videosTableBody'>
            {
              monitoredVideos?.data?.map((message, index) => (
                <tr key={index}>
                  <td className='videosTableBodyTd' style={{ cursor: 'pointer' }} onClick={() => {
                    window.open(`https://www.youtube.com/channel/${message.channelId}`, '_blank');
                  }}
                  >{message.channelName.length > 25 ? message.channelName.substring(0, 25) + '...' : message.channelName}</td>
                  <td className='videosTableBodyTd' style={{ cursor: 'pointer' }} onClick={() => {
                    window.open(`https://www.youtube.com/watch?v=${message.videoId}`, '_blank');
                  }}
                  >{message.title.length > 25 ? message.title.substring(0, 25) + '...' : message.title}</td>
                  <td className='videosTableBodyTd' style={{ cursor: 'copy' }} onClick={() => {
                    navigator.clipboard.writeText(message.videoId);
                    toast.success('Video Id copied to clipboard');
                  }}
                  >{message.videoId}</td>
                  {/* <td className='videosTableBodyTd'>
                    <button onClick={() => {
                      axios.post(`https://younity-backend.penpencil.co/v1/ytvideo/stop/${message?.videoId}`, null, { headers: {} }
                      ).then(response => {
                        console.log('[Success] Stop Monitoring');
                        // console.log('Response:', response.data);
                        toast.success('Monitoring Stopped!');
                      }).catch(error => {
                        console.error('Error:', error);
                      })
                    }}>Stop</button>
                  </td> */}
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default ActiveVideos
