import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import './original-dump.css';

const OriginalDump = () => {
  const { id } = useParams();
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    fetchPollData();
  }, []);
  const fetchPollData = () => {
    axios.get(`https://younity-backend.penpencil.co/v1/poll/getOriginalDump/${id}`)
      .then(response => {
        setMessages(response.data.data);
      }).catch(error => {
        console.error('Error:', error);
      });
  }

  return (
    <div>
      <h3>{`Poll Id: ${id}`}</h3>
      <table>
        <thead>
          <tr>
            <th>Serial No.</th>
            <th>Name</th>
            <th>Message</th>
            <th>Published At</th>
            <th>Created At</th>
          </tr>
        </thead>
        <tbody>
          {
            messages.map((message, index) => (
              <tr key={message._id}>
                <td>{index + 1}</td>
                <td style={{maxWidth: 100}}>{message.displayName}</td>
                <td style={{maxWidth: 250}}>{message.displayMessage}</td>
                <td>{moment(message.publishedAt).format('DD/MM/YY HH:mm:ss')}</td>
                <td>{moment(message.createdAt).format('DD/MM/YY HH:mm:ss')}</td>
              </tr>
            ))
          }
        </tbody>
      </table>
    </div>
  )
}

export default OriginalDump
