import axios from 'axios';
import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

const CumLeaderboard = () => {
  const [leaderboardData, setLeaderboardData] = useState([]);

  const { videoId } = useParams();

  const fetchLeaderboardData = () => {
    axios.get(`https://younity-backend.penpencil.co/v1/poll/cumulative-leaderboard/${videoId}`)
    .then(response => {
      setLeaderboardData(response.data.data);
      console.log(response.data.data);
    }).catch(error => {
      console.error('Error:', error);
    })
  }

  useEffect(() => {
    fetchLeaderboardData();
  }, []);

  return (
    <div>
      <h3>{`Video Id: ${videoId}`}</h3>
      <table>
        <thead>
          <tr>
            <th>Rank</th>
            <th>Image</th>
            <th>Name</th>
            <th>Questions Attemted</th>
            <th>Correct Answers</th>
            <th>Average Time Taken</th>
          </tr>
        </thead>
        <tbody>
          {
            leaderboardData?.leaderboard?.map((message, index) => (
              <tr key={message._id}>
                <td>{message?.currentRank}</td>
                <td><img style={{height: 25, borderRadius: 25}} src={message?.creatorProfile} alt={message?.displayName} /></td>
                <td>{message?.createdBy}</td>
                <td>{message?.questionsAttempted}</td>
                <td>{message?.correctAnswers}</td>
                <td>{message?.averageTimeTaken}s</td>
              </tr>
            ))
          }
        </tbody>
      </table>
    </div>
  )
}

export default CumLeaderboard
