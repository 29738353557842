import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import moment from 'moment';
import axios from 'axios';

const PollResult = () => {
  const [pollData, setPollData] = useState([]);

  const { id } = useParams();

  const getPollResult = () => {
    axios.get(`https://younity-backend.penpencil.co/v1/poll/get-results/${id}`)
      .then(response => {
        setPollData(response.data.data);
        console.log(response.data.data);
      }).catch(error => {
        console.error('Error:', error);
      });
  };

  useEffect(() => {
    getPollResult();
  }, []);

  return (
    <div>
      <h3>{`Poll Id: ${id}`}</h3>
      <table>
        <thead>
          <tr>
            <th>Rank</th>
            <th>Image</th>
            <th>Name</th>
            <th>Answer</th>
            <th>Time Taken</th>
            <th>Created At</th>
          </tr>
        </thead>
        <tbody>
          {
            pollData?.leaderboard?.map((message, index) => (
              <tr key={message._id}>
                <td>{index + 1}</td>
                <td><img style={{height: 25, borderRadius: 25}} src={message?.creatorProfile} alt={message?.displayName} /></td>
                <td>{message?.createdBy}</td>
                <td>{message?.answer.join(',')}</td>
                <td>{message?.timeTaken}s</td>
                <td>{moment(message?.createdAt)?.format('DD/MM/YY HH:mm:ss')}</td>
              </tr>
            ))
          }
        </tbody>
      </table>
    </div>
  )
}

export default PollResult
