import React, { useState } from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './routes/home/home'
import ChatDump from './routes/chat-dump/chat-dump';
import OriginalDump from './routes/original-dump/original-dump';
import PollResult from './routes/poll-result/poll-result';
import CumLeaderboard from './routes/cum-leaderboard/cum-leaderboard';
import VideoDoubts from './routes/video-doubts/video-doubts';
import { Toaster } from 'react-hot-toast';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';

const UTKARSH_CLIENT_ID = "468514124165-k8t8u969nim3ggiu56fua634rgohs8j4.apps.googleusercontent.com";
const PW_CLIENT_ID = "301313795844-ofhamrp4vvdkfq40hemv8i5h3mr2suet.apps.googleusercontent.com";

const App = () => {
  const [selectedClient, setSelectedClient] = useState('pw');

  const handleSelectedClient = (e) => {
    setSelectedClient(e.target.value);
  };
  
  return (
    <>
      <GoogleOAuthProvider clientId={selectedClient == "pw" ? PW_CLIENT_ID : UTKARSH_CLIENT_ID}>
        {
          localStorage.getItem('token') &&
          <BrowserRouter>
            <Toaster />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/chat-dump/:id" element={<ChatDump />} />
              <Route path="/original-chat-dump/:id" element={<OriginalDump />} />
              <Route path="/poll-result/:id" element={<PollResult />} />
              <Route path="/cum-leaderboard/:videoId" element={<CumLeaderboard />} />
              <Route path="/video-doubts/:id" element={<VideoDoubts />} />
            </Routes>
          </BrowserRouter>
        }
        {
          !localStorage.getItem('token') &&
          <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', height: '95vh' }}>
            <h4>Login with {selectedClient.toUpperCase()} Account</h4>
            <div>
              <select value={selectedClient} onChange={handleSelectedClient} style={{ width: 180 }}>
                  <option value="pw">PW</option>
                  <option value="utkarsh">Utkarsh</option>
                </select>
            </div>
            <GoogleLogin
              theme='outline'
              onSuccess={credentialResponse => {
                localStorage.setItem('token', credentialResponse?.credential);
                window.location.reload();
              }}
              onError={() => {
                console.log('Login Failed');
              }}
            />
          </div>
        }
      </GoogleOAuthProvider>
    </>
  )
}

export default App
