import React, { useState } from 'react';
import './home.css';
import axios from 'axios';
import MonitorPoll from '../../components/monitor-poll/monitor-poll';
import ActiveVideos from '../../components/active-videos/active-videos';
import toast from 'react-hot-toast';
import UpdateText from '../../components/update-text/update-text';

function Home() {
  const [moniterVideoID, setMonitorVideoID] = useState('');
  const [startPollId, setStartPollId] = useState('');
  const [pollType, setPollType] = useState('SINGLE');
  const [pollDuration, setPollDuration] = useState(30);
  const [stopPollId, setStopPollId] = useState('');
  const [correctAnswer, setCorrectAnswer] = useState(1); // Default correct answer
  const [generateResultPollId, setGenerateResultPollId] = useState(''); // Default correct answer
  const [pollTimer, setPollTimer] = useState(null);
  const [pollEndTime, setPollEndTime] = useState(null);

  const handleMonitorStart = (event) => {
    event.preventDefault();
    if (!moniterVideoID) {
      toast.error('Please enter Video ID');
      return;
    }
    axios.post(`https://younity-backend.penpencil.co/v1/ytvideo/start/${moniterVideoID}`, null, { headers: {} }
    ).then(response => {
      console.log('[Success] Start Monitoring');
      // console.log('Response:', response.data);
      toast.success('Monitoring Started');
    }).catch(error => {
      console.error('Error:', error);
    })
  };

  const handleMonitorStop = (event) => {
    event.preventDefault();
    if (!moniterVideoID) {
      toast.error('Please enter Video ID');
      return;
    }
    axios.post(`https://younity-backend.penpencil.co/v1/ytvideo/stop/${moniterVideoID}`, null, { headers: {} }
    ).then(response => {
      console.log('[Success] Stop Monitoring');
      // console.log('Response:', response.data);
      toast.success('Monitoring Stopped');
    }).catch(error => {
      console.error('Error:', error);
    })
  };

  const handlePollStart = (event) => {
    event.preventDefault();
    if (!startPollId) {
      toast.error('Please enter Video ID');
      return;
    }
    const data = {
      pollType: pollType,
      duration: Number(pollDuration),
      options: 4,
      videoId: startPollId,
    };
    const headers = {};
    axios.post('https://younity-backend.penpencil.co/v1/poll', data, { headers })
      .then(response => {
        console.log('[Success] Start Poll');
        console.log('Poll ID:', response.data.data._id);
        console.log('Response:', response.data);
        setStopPollId(response.data.data._id);

        // Set the poll end time (for example, 10 minutes from now)
        const currentTime = new Date().getTime();
        const pollDurationVariable = Number(pollDuration) * 1000;
        const endTime = new Date(currentTime + pollDurationVariable);
        setPollEndTime(endTime);

        // Start the timer
        const interval = setInterval(() => {
          const now = new Date().getTime();
          // log time remaining in secs
          console.log('Time Remaining:', (Math.ceil((endTime.getTime() - now) / 1000) + 1), 'secs');
          setStopPollId(response.data.data._id);
          if (now >= endTime.getTime()) {
            clearInterval(interval);
            setPollTimer(null);
            automaticPollStop(response.data.data._id);
          }
        }, 1000); // Check every second
        setPollTimer(interval);
        toast.success('Poll Started');
      })
      .catch(error => {
        console.error('Error:', error);
      })
  };

  const automaticPollStop = (pollId) => {
    axios.patch(`https://younity-backend.penpencil.co/v1/poll/stop-poll/${pollId}`, null, { headers: {} })
      .then(response => {
        console.log('[Success] Stop Poll');
        console.log('Response:', response.data);
        setGenerateResultPollId(response.data.data._id);
        if (pollTimer) {
          clearInterval(pollTimer);
          setPollTimer(null);
          setPollEndTime(null);
        }
        toast.success('Poll Stopped');
      })
      .catch(error => {
        console.error('Error:', error);
      })
  };

  const handlePollStop = (event) => {
    if (event) {
      event.preventDefault();
    }
    if (!stopPollId) {
      toast.error('Please enter Poll ID');
      return;
    }
    axios.patch(`https://younity-backend.penpencil.co/v1/poll/stop-poll/${stopPollId}`, null, { headers: {} })
      .then(response => {
        console.log('[Success] Stop Poll');
        console.log('Response:', response.data);
        setGenerateResultPollId(response.data.data._id);
        if (pollTimer) {
          clearInterval(pollTimer);
          setPollTimer(null);
          setPollEndTime(null);
        }
        toast.success('Poll Stopped');
      })
      .catch(error => {
        console.error('Error:', error);
      })
  };


  const generateResult = (event) => {
    event.preventDefault();
    if (!generateResultPollId) {
      toast.error('Please enter Poll ID');
      return;
    }
    let answer = [];
    if (pollType === 'MULTIPLE') {
      answer = String(correctAnswer)?.split('').map(Number);
    } else {
      answer = [Number(correctAnswer)];
    }
    let data = {
      correctOptions: answer,
    };
    console.log(data);
    let headers = {};
    axios.post(`https://younity-backend.penpencil.co/v1/poll/results/${generateResultPollId}`, data, { headers })
      .then(response => {
        console.log('[Success] Geneate Result');
        console.log('Response:', response.data);
        toast.success('Result Generated');
      })
      .catch(error => {
        console.error('Error:', error);
      })
  };

  return (
    <div className='App'>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        {/* <h2>Functions</h2> */}
        <form onSubmit={handleMonitorStart} className='topForms'>
          <h4>Monitor</h4>
          <input
            value={moniterVideoID}
            onChange={(e) => setMonitorVideoID(e.target.value)}
            placeholder="Video ID"
            style={{ width: 180 }}
          ></input>
          <button type="submit">Start</button>
          <button onClick={handleMonitorStop}>Stop</button>
        </form>

        <form onSubmit={handlePollStart} className='topForms'>
          <h4>Create a Poll</h4>
          <input
            value={startPollId}
            onChange={(e) => setStartPollId(e.target.value)}
            placeholder="Video ID"
            style={{ width: 180 }}
          ></input>
          <select value={pollType} onChange={(e) => setPollType(e.target.value)}>
            <option value="SINGLE">SINGLE</option>
            <option value="MULTIPLE">MULTIPLE</option>
            <option value="INTEGER">INTEGER</option>
            <option value="VOTE">VOTE</option>
          </select>
          <select value={pollDuration} onChange={(e) => setPollDuration(e.target.value)}>
            <option value={5}>5</option>
            <option value={30}>30</option>
            <option value={60}>60</option>
            <option value={90}>90</option>
            <option value={120}>120</option>
          </select>
          <button type="submit">Start</button>
        </form>

        <form onSubmit={handlePollStop} className='topForms'>
          <h4>Stop Poll</h4>
          <input
            value={stopPollId}
            onChange={(e) => setStopPollId(e.target.value)}
            placeholder="Poll ID"
            style={{ width: 180 }}
          ></input>
          <button type="submit">Stop</button>
        </form>

        <form onSubmit={generateResult} className='topForms'>
          <h4>Generate Result</h4>
          <input
            value={generateResultPollId}
            onChange={(e) => setGenerateResultPollId(e.target.value)}
            placeholder="Poll ID"
            style={{ width: 180 }}
          ></input>
          {
            pollType === 'SINGLE' &&
            <select value={correctAnswer} onChange={(e) => setCorrectAnswer(e.target.value)}>
              <option value={1}>A</option>
              <option value={2}>B</option>
              <option value={3}>C</option>
              <option value={4}>D</option>
            </select>
          }
          {
            pollType === 'MULTIPLE' &&
            <select value={correctAnswer} onChange={(e) => setCorrectAnswer(e.target.value)}>
              <option value={1}>A</option>
              <option value={2}>B</option>
              <option value={3}>C</option>
              <option value={4}>D</option>
              <option value={12}>AB</option>
              <option value={13}>AC</option>
              <option value={14}>AD</option>
              <option value={23}>BC</option>
              <option value={24}>BD</option>
              <option value={34}>CD</option>
              <option value={123}>ABC</option>
              <option value={124}>ABD</option>
              <option value={134}>ACD</option>
              <option value={234}>BCD</option>
              <option value={1234}>ABCD</option>
            </select>
          }
          {
            pollType === 'INTEGER' &&
            <input type='number' value={correctAnswer} onChange={(e) => setCorrectAnswer(e.target.value)} placeholder="Answer" style={{ width: 100 }}></input>
          }
          {
            pollType === 'VOTE' &&
            <select value={correctAnswer} onChange={(e) => setCorrectAnswer(e.target.value)}>
              <option value="1">YES</option>
            </select>
          }
          <button type="submit">Generate</button>
        </form>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <MonitorPoll />
        <ActiveVideos />
      </div>
      <UpdateText />
    </div>
  );
}

export default Home;
