import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useParams } from 'react-router-dom';

const VideoDoubts = () => {
  const { id } = useParams();
  console.log(id);
  const [doubts, setDoubts] = useState([]);

  const getDoubts = () => {
    axios.get(`https://younity-backend.penpencil.co/v1/doubt/teacher/video/${id}`)
      .then(res => {
        console.log(res.data);
        setDoubts(res.data);
      })
  }

  const resolveDoubt = (doubtId) => {
    axios.patch(`https://younity-backend.penpencil.co/v1/doubt/${doubtId}`, {
      solutionTimestamp: new Date().toISOString()
    })
      .then(res => {
        console.log(res.data);
      })
  }

  useEffect(() => {
    getDoubts();
  }, [])
  
  return (
    <div>
      <p>Doubts</p>
      {
        doubts?.data?.map((doubt, index) => (
          <div style={{ backgroundColor: '#b9b9b935', borderRadius: 5, padding: 10, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} key={index}>
            <div>
              <p style={{ fontSize: 16 }}>{doubt.createdBy}</p>
              <p style={{ fontSize: 14, color: '#666' }}>{doubt.doubt}</p>
            </div>
            <div style={{alignSelf: 'center'}}>
              <p onClick={() => {
                resolveDoubt(doubt._id);
              }} style={{backgroundColor: '#757575', color: '#fff', padding: 5, fontSize: 12, borderRadius: 25, paddingLeft: 10, paddingRight: 10, cursor: 'pointer'}}>Delete</p>
            </div>
          </div>
        ))
      }
    </div>
  )
}

export default VideoDoubts
