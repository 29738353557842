import React, { useEffect, useState } from 'react';
import moment from 'moment/moment';
import './monitor-poll.css';
import toast from 'react-hot-toast';

const MonitorPoll = () => {
  const [allPolls, setAllPolls] = useState([]);
  const [recentPolls, setRecentPolls] = useState([]);
  const [channelsData, setChannelsData] = useState([]);
  const [selectedChannel, setSelectedChannel] = useState('all');
  const [selectedVideo, setSelectedVideo] = useState('select');
  const [selectedVideoPolls, setSelectedVideoPolls] = useState([]);

  useEffect(() => {
    listTodaysPolls();
    const interval = setInterval(() => {
      listTodaysPolls();
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (selectedChannel && selectedVideo) {
      const video = channelsData?.find(channel => channel?.channelId === selectedChannel)
        ?.videos?.find(video => video.videoId === selectedVideo);
      setSelectedVideoPolls(video ? video.polls : []);
    } else {
      setSelectedVideoPolls([]);
    }
  }, [selectedChannel, selectedVideo, channelsData]);

  const listTodaysPolls = () => {
    fetch('https://younity-backend.penpencil.co/v1/poll/list')
      .then(response => response.json())
      .then(data => {
        setAllPolls(data.data);
        // channel data filter
        const channelsData = organizeDataByChannels(data.data);
        setChannelsData(channelsData);
        // console.log('Channels Data:', channelsData);
        // recent polls filter
        let dataFromApi = data.data;
        let filteredData = [];
        let filteredVideoIds = [];
        dataFromApi.forEach((poll) => {
          if (filteredVideoIds.includes(poll.videoId)) {
            return;
          } else {
            filteredData.push(poll);
            filteredVideoIds.push(poll.videoId);
          }
        })
        setRecentPolls(filteredData);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };

  function organizeDataByChannels(data) {
    const channelsMap = {};

    data.forEach(poll => {
      const { videoId, channelId, videoTitle } = poll;
      if (!channelsMap[channelId]) {
        channelsMap[channelId] = {
          channelId,
          channelTitle: poll.channelTitle,
          videos: {},
        };
      }

      if (!channelsMap[channelId].videos[videoId]) {
        channelsMap[channelId].videos[videoId] = {
          videoId,
          videoTitle,
          polls: [],
        };
      }

      channelsMap[channelId].videos[videoId].polls.push(poll);
    });

    const channelsArray = Object.keys(channelsMap).map(channelId => {
      const { channelId: id, channelTitle, videos } = channelsMap[channelId];
      const videosArray = Object.keys(videos).map(videoId => videos[videoId]);
      return {
        channelId: id,
        channelTitle,
        videos: videosArray,
      };
    });

    return channelsArray;
  }

  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <h3>Poll Analytics</h3>
        {/* <br /> */}
        <select
          style={{
            alignSelf: 'center',
          }}
          value={selectedChannel}
          onChange={(event) => {
            const channelId = event.target.value;
            setSelectedChannel(channelId);
            setSelectedVideo('select');
          }}
        >
          <option value={'all'}>Recent Polls</option>
          {
            channelsData.map((channel, index) => (
              <option key={index} value={channel?.channelId}>{channel?.channelTitle || "--"}</option>
            ))
          }
        </select>
        {
          selectedChannel !== 'all' &&
          <select
            style={{
              width: 180,
              alignSelf: 'center',
            }}
            value={selectedVideo}
            onChange={(event) => {
              const videoId = event.target.value;
              setSelectedVideo(videoId);
            }}
          >
            <option value={'select'}>Select Video</option>
            {
              selectedChannel && channelsData?.find(channel => channel?.channelId === selectedChannel)?.videos.map((video, index) => (
                <option key={index} value={video.videoId}>{video.videoTitle || "--"}</option>
              ))
            }
          </select>
        }
        {
          selectedVideo !== 'select' &&
          // <button onClick={() => {
          //   window.open(`/cum-leaderboard/${selectedVideo}`);
          // }}>Cumulative</button>
          <p className='cumLead' onClick={() => {
            window.open(`/cum-leaderboard/${selectedVideo}`);
          }}>
           Cumulative
          </p>
        }
        <p style={{ fontSize: 12, alignSelf: 'center' }}>{`Polls Today: ${allPolls.length} ~ Total Videos: ${channelsData?.reduce((acc, channel) => acc + channel.videos.length, 0)} ~ Total Channels: ${channelsData.length}`}</p>
      </div>

      {
        selectedVideoPolls.length > 0 &&
        <h3>Total No. of Poll: {selectedVideoPolls.length}</h3>
      }

      <table>
        <thead>
          <tr>
            <th>No.</th>
            {selectedChannel == 'all' && <th>Channel</th>}
            {selectedChannel == "all" && <th>Video</th>}
            <th>Poll Id</th>
            <th>Type</th>
            <th>Time</th>
            <th>Polling Status</th>
            <th>Created At</th>
            <th>Closed At</th>
            <th>Correct Answer</th>
            <th>Video Id</th>
            <th>Buttons</th>
          </tr>
        </thead>
        <tbody>
          {selectedChannel !== 'all' && selectedVideoPolls.map((poll, index) => (
            <tr style={{
              backgroundColor: poll.closed ? '' : '#4bb54350',
            }} key={index}>
              <td>{index + 1}</td>
              <td style={{ cursor: 'copy' }} onClick={() => {
                navigator.clipboard.writeText(poll._id);
                toast.success('Poll Id copied to clipboard');
              }}
              >copy</td>
              <td>{poll?.pollType || '--'}</td>
              <td>{poll?.duration || '--'}</td>
              <td>{poll?.closed ? 'Finished' : 'Ongoing'}</td>
              <td>{moment(poll?.createdAt)?.format('HH:mm:ss') || '--'}</td>
              <td>{moment(poll?.closedAt)?.format('HH:mm:ss') || '--'}</td>
              <td>{
                poll?.pollType === 'INTEGER'
                ? poll?.correctAnswer
                : poll?.correctAnswer?.map(answer => {
                  if (answer == 1) return 'A';
                  if (answer == 2) return 'B';
                  if (answer == 3) return 'C';
                  if (answer == 4) return 'D';
                }).join(',')}
              </td>
              <td style={{ cursor: 'copy' }} onClick={() => {
                navigator.clipboard.writeText(poll.videoId);
                toast.success('Video Id copied to clipboard');
              }}
              >{"copy" || '--'}</td>
              <td style={{ minWidth: '120px' }}>
                <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: 5 }}>
                  <button style={{ fontSize: 10 }} onClick={() => {
                    // navigate(`/chat-dump/${poll._id}`);
                    window.open(`/original-chat-dump/${poll._id}`);
                  }}>A</button>
                  <button style={{ fontSize: 10 }} onClick={() => {
                    // navigate(`/original-chat-dump/${poll._id}`);
                    window.open(`/chat-dump/${poll._id}`);
                  }}>F</button>
                  <button style={{ fontSize: 10 }} onClick={() => {
                    // navigate(`/original-chat-dump/${poll._id}`);
                    window.open(`/poll-result/${poll._id}`);
                  }}>R</button>
                </div>
              </td>
            </tr>
          ))}
          {selectedChannel == 'all' && recentPolls?.map((poll, index) => (
            <tr style={{
              backgroundColor: poll.closed ? '' : '#4bb54350',
            }} key={index}>
              <td>{index + 1}</td>
              <td style={{ cursor: 'pointer' }} onClick={() => {
                window.open(`https://www.youtube.com/channel/${poll.channelId}`);
              }}
              >{poll?.channelTitle || '--'}</td>
              <td style={{ cursor: 'pointer' }} onClick={() => {
                window.open(`https://www.youtube.com/watch?v=${poll.videoId}`);
              }}>{poll?.videoTitle?.length > 20 ? poll?.videoTitle.slice(0, 20) + '...' : poll?.videoTitle || '--'}</td>
              <td style={{ cursor: 'copy' }} onClick={() => {
                navigator.clipboard.writeText(poll._id);
                toast.success('Poll Id copied to clipboard');
              }}>copy</td>
              <td>{poll?.pollType || '--'}</td>
              <td>{poll?.duration || '--'}</td>
              <td>{poll?.closed ? 'Finished' : 'Ongoing'}</td>
              <td>{moment(poll?.createdAt)?.format('HH:mm:ss') || '--'}</td>
              <td>{moment(poll?.closedAt)?.format('HH:mm:ss') || '--'}</td>
              <td>{
                poll?.pollType === 'INTEGER'
                ? poll?.correctAnswer
                : poll?.correctAnswer?.map(answer => {
                  if (answer == 1) return 'A';
                  if (answer == 2) return 'B';
                  if (answer == 3) return 'C';
                  if (answer == 4) return 'D';
                }).join(',')}
              </td>
              <td style={{ cursor: 'copy' }} onClick={() => {
                navigator.clipboard.writeText(poll.videoId);
                toast.success('Video Id copied to clipboard');
              }}>{"copy" || '--'}</td>
              <td style={{ minWidth: '210px' }}>
                <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: 5 }}>
                  <button style={{ fontSize: 10 }} onClick={() => {
                    // navigate(`/chat-dump/${poll._id}`);
                    window.open(`/original-chat-dump/${poll._id}`);
                  }}>A</button>
                  <button style={{ fontSize: 10 }} onClick={() => {
                    // navigate(`/original-chat-dump/${poll._id}`);
                    window.open(`/chat-dump/${poll._id}`);
                  }}>F</button>
                  <button style={{ fontSize: 10 }} onClick={() => {
                    // navigate(`/original-chat-dump/${poll._id}`);
                    window.open(`/poll-result/${poll._id}`);
                  }}>R</button>
                  <button style={{ fontSize: 10 }} onClick={() => {
                    // navigate(`/original-chat-dump/${poll._id}`);
                    window.open(`/cum-leaderboard/${poll.videoId}`);
                  }}>C</button>
                  <button style={{ fontSize: 10 }} onClick={() => {
                    window.open(`/video-doubts/${poll.videoId}`);
                  }}>D</button>
                  <button style={{ fontSize: 10 }} onClick={() => {
                    setSelectedChannel(poll.channelId);
                    setSelectedVideo(poll.videoId);
                  }}>AP</button>

                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default MonitorPoll;
