import React from 'react'
import axios from 'axios'

const UpdateText = () => {
  const [text, setText] = React.useState('')
  const [currentText, setCurrentText] = React.useState('')

  const updateText = () => {
    axios.post(`https://younity-backend.penpencil.co/v1/poll/text`, {
      pollRunningText: text
    }, { headers: {} }
    ).then(res => {
      console.log('[Success] Update Text');
      getText();
    }).catch(error => {
      console.error('Error:', error);
    })
  }

  const getText = () => {
    axios.get(`https://younity-backend.penpencil.co/v1/poll/text`, { headers: {} }
    ).then(res => {
      console.log('[Success] Get Text');
      setCurrentText(res.data.data.text)
    }).catch(error => {
      console.error('Error:', error);
    })
  }

  React.useEffect(() => {
    getText();
  }, []);

  return (
    <div style={{ marginLeft: 10 }}>
      <h3>Update Poll Text</h3>
      <p style={{fontSize: 14}}>{currentText}</p>
      <input type="text" value={text} onChange={(e) => setText(e.target.value)} />
      <button onClick={updateText}>Update Text</button>
    </div>
  )
}

export default UpdateText
